import { User } from '../user';
import { Plantation } from '../plantation';
import type { Timestamp } from '@/interfaces/common';

export interface Recipient extends Timestamp {
  country: string;
  currency: string;
  swift?: string;
  iban?: string;
  routingNumber?: string;
  accountNumber?: string;
  plantation: Plantation;
  creator: User;
  status: RecipientStatus;
  type: RecipientType;
  beneficiary: string;
}

export enum RecipientType {
  ACH = 'ACH',
  SWIFT = 'SWIFT',
}

export enum RecipientStatus {
  WAITING = 'WAITING',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
}

export enum RecipientCreateType {
  NEW = 'NEW',
  EXIST = 'EXIST',
}

export enum RecipientCreateTypeName {
  NEW = 'Новый',
  EXIST = 'Существующий',
}

export const RecipientCreateTypeList = [
  { _id: RecipientCreateType.NEW, name: RecipientCreateTypeName.NEW },
  { _id: RecipientCreateType.EXIST, name: RecipientCreateTypeName.EXIST },
];

export const RecipientCurrencyList = (currency: string) => {
  return {
    USD: '$',
    USDT: '$',
    RUB: '₽',
    EUR: '€',
  }[currency];
};

// DTO

export interface CreateRecipientDto {
  plantation?: string;
  plantationName?: string;

  number: number;
  city: string;
  zip: number;
  country: string;
  swift?: string;
  routingNumber?: number;

  type: RecipientCreateType;
}
